import { atom } from 'recoil'

export interface CourseDemo {
  id: string
  courseLink: string
  name: string
  shortName: string
  description: string
  createdAt: number
  updatedAt: number
  buttonText: string
  linkOverride?: string
  demoPageOverride?: string
  imageUrl?: string
}

export const mockData: CourseDemo[] = [
  {
    id: '1',
    courseLink: 'analytics',
    name: 'Advanced analytics',
    shortName: 'analytics',
    description: `Our advanced analytics program prepares learners to run select and use the right tools to solve complex data science problems.
    <br><br>
    Learners develop the ability to work in Python from data pre-processing through to visualisation, and to build and assess machine learning models.
    <br><br>
    This demo topic introduces data sampling.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    demoPageOverride:
      'https://content.mentem.co/lx/7eeb0279-d937-4a26-8ac5-583d97c260bb/index.html',
    imageUrl: '/images/course-analytics.jpg',
  },
  {
    id: '2',
    courseLink: 'bpm',
    name: 'Business process modelling (BPM)',
    shortName: 'BPM',
    description: `Our 4-course BPM program is a UNSW-recognised microcredential.
    <br><br>
    Learners develop key skills including business process identification, process improvement, BPM notation, systems thinking, and process redesign.
    <br><br>
    This demo topic from early in the program introduces digital transformation.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    imageUrl: '/images/course-bpm.jpg',
    demoPageOverride:
      'https://content.mentem.co/lx/2519387e-b99d-41da-9111-ffb8f5099aee/index.html',
  },
  {
    id: '3',
    courseLink: 'coaching',
    name: 'Coaching',
    shortName: 'coaching',
    description: `Our coaching program prepares candidates to coach learners in their organisation.
    <br><br>
    Learners cover topics including the role and benefits of coaching, popular coaching frameworks, running effective coaching sessions, and communication skills for effective coaching.
    <br><br>
    This demo topic is on establishing relationships.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    imageUrl: '/images/course-coaching.jpg',
    demoPageOverride:
      'https://content.mentem.co/lx/e354496e-c83e-4780-81c8-0089877a5f59/index.html',
  },
  {
    id: '4',
    courseLink: 'leadership',
    name: 'Digital leadership',
    shortName: 'leadership',
    description: `Our digital leadership program redefines leadership in the age of digital innovation. 
    <br><br>
    Learners develop the digital mindset, digital toolset and digital skillset to drive digital uplift in their organisation.
    <br><br>
    This demo topic introduces ecosystem platforms.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    imageUrl: '/images/course-leadership.jpg',
    demoPageOverride:
      'https://content.mentem.co/lx/88753d39-bb10-49b5-99bd-bd2f8e19671d/index.html',
  },
  {
    id: '5',
    courseLink: 'data',
    name: 'Data analytics',
    shortName: 'data',
    description: `Our 4-course data analytics program is a UNSW-recognised microcredential. 
    <br><br>
    Learners progress from data foundations through to data visualisation and onto statistics and AI in analytics.
    <br><br>
    This demo topic introduces difference-in-difference.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    imageUrl: '/images/course-data.jpg',
    demoPageOverride:
      'https://content.mentem.co/lx/6c1977ce-b1bc-491e-9235-d6602113fd7c/index.html',
  },
  {
    id: '6',
    courseLink: 'cyber',
    name: 'Cybersecurity',
    shortName: 'cyber',
    description: `Our cybersecurity program covers security and assets, threads and exploits, and the security mindset and proactive approaches to threats and risks.
    <br><br>
    This demo topic introduces social engineering.
    `,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    buttonText: 'Course demo',
    imageUrl: '/images/course-cyber.jpg',
    demoPageOverride:
      'https://content.mentem.co/lx/9ffe0e83-4eb3-44ec-824c-fb3bc2fc3776/index.html',
  },
]
export const courseDemoState = atom<CourseDemo[]>({
  key: 'course-demo',
  default: mockData,
})
