import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENV,
})

const gtmId = process.env.REACT_APP_GTM_ID
if (gtmId) {
  const tagManagerArgs = {
    gtmId: gtmId,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  }
  TagManager.initialize(tagManagerArgs)
  console.log('gtmId', gtmId)
} else {
  console.warn('No GTM ID found')
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
