import { PropsWithChildren } from 'react'
import { RecoilRoot } from 'recoil'
import { Router } from './router'
import { BaseLayout } from '../ui/pages/BaseLayout'

export const RootProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <RecoilRoot>
      <div>
        <Router>
          <BaseLayout>{children}</BaseLayout>
        </Router>
      </div>
    </RecoilRoot>
  )
}
