import './EmailPage.css'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Checkbox } from '@mui/material'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { Footer } from '../common/Footer'

export const EmailPage: React.FC = () => {
  const [usersEmail, setUsersEmail] = useState(
    localStorage.getItem('usersEmail') || '',
  )
  const [sendMeEmails, setSendMeEmails] = useState(
    localStorage.getItem('sendMeEmails') === 'true',
  )
  const navigate = useNavigate()

  const params = useParams()
  const courseId: string = params.courseId as string

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      document.getElementById('continueButton')?.click()
    }
  }

  const handlePostRequest = async () => {
    if (!usersEmail) {
      // @ts-ignore
      document.getElementById('errorInvalidEmail').style.display = 'none'
      // @ts-ignore
      document.getElementById('errorNoEmail').style.display = 'block'
      return false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usersEmail)) {
      // @ts-ignore
      document.getElementById('errorInvalidEmail').style.display = 'block'
      // @ts-ignore
      document.getElementById('errorNoEmail').style.display = 'none'
      return false
    } else {
      // @ts-ignore
      document.getElementById('errorInvalidEmail').style.display = 'none'
      // @ts-ignore
      document.getElementById('errorNoEmail').style.display = 'none'
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/save-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        usersEmail: usersEmail,
        sendMeEmails,
        demoStep: '1',
        course: courseId,
      }),
    })

    if (res.status === 201) {
      localStorage.setItem('usersEmail', usersEmail)
      localStorage.setItem('sendMeEmails', JSON.stringify(sendMeEmails))
      navigate(`/courses/${courseId}`)
    } else {
      console.error('todo: better error handling')
      console.error(res)
      Sentry.captureException(res)
    }
  }

  return (
    <>
      <div
        className="email-header-container"
        style={{
          backgroundImage: `url(/images/header-bg-email.jpg)`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(/images/header-bg-email.svg)`,
          }}
        >
          <p>We'd love to show you around.</p>
        </div>
      </div>
      <div className="email-content-container">
        <div className="email-card-container">
          <input
            type="email"
            onKeyDown={handleKeyDown}
            className="email-input"
            placeholder="Your business email*"
            value={usersEmail}
            onInput={(e: any) => {
              setUsersEmail(e.target.value)
            }}
          />
          <small
            id="errorInvalidEmail"
            className="form-text text-danger"
            style={{ display: 'none' }}
          >
            <ReportProblemOutlinedIcon className={'align-bottom'} />
            &nbsp;&nbsp; Oops! Your email is invalid.
          </small>
          <small
            id="errorNoEmail"
            className="form-text text-danger"
            style={{ display: 'none' }}
          >
            <ReportProblemOutlinedIcon className={'align-bottom'} />
            &nbsp;&nbsp; Oops! You need to submit your email to continue.
          </small>
          <div className="form-checkbox-container">
            <Checkbox
              style={{
                padding: 0,
                color: '#000',
              }}
              id="emailCheckbox"
              checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
              checked={sendMeEmails}
              onInput={() => {
                setSendMeEmails(!sendMeEmails)
              }}
            />
            <label className="form-checkbox-label" htmlFor="emailCheckbox">
              I agree to receive follow-up emails from Mentem
            </label>
          </div>
          <p className="form-text-muted">
            By providing us with your email, you agree to the terms of our{' '}
            <a
              href={'https://www.mentem.co/privacy/'}
              target={'_blank'}
              rel="noreferrer"
            >
              <span className="link">Privacy Policy</span>
            </a>
          </p>
          <div className="text-center">
            <button
              onClick={handlePostRequest}
              className={'btn-branded btn'}
              id="continueButton"
            >
              Continue
            </button>
          </div>
        </div>

        <div className="email-list-container">
          <ul>
            <li className="email-list-item">
              <img className="email-icon-tick" src="/images/tick.svg" alt="" />
              <span>
                See <span className="highlight">sample topics</span> from some
                of our most in-demand courses
              </span>
            </li>
            <li className="email-list-item">
              <img className="email-icon-tick" src="/images/tick.svg" alt="" />
              <span>
                Get a feel for the{' '}
                <span className="highlight">Mentem learning experience</span>
              </span>
            </li>
            <li className="email-list-item">
              <img className="email-icon-tick" src="/images/tick.svg" alt="" />
              <span>
                Just enter an email address and{' '}
                <span className="highlight">get started</span> straight away
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  )
}
