import './topic.css'
import { useNavigate } from 'react-router-dom'
import { CourseDemo } from '../../state/course-demo-state'
import { useMediaQuery } from 'react-responsive'
import { useCallback, useEffect, useState } from 'react'

interface TopicProps {
  data: CourseDemo
  disabled: boolean
  onSelected?: (id: string | null) => void
}

export const Topic = ({ data, disabled, onSelected }: TopicProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 575.98px)' })
  const alt = data.name.replace(/\s/g, '-').toLowerCase()
  const navigate = useNavigate()
  const [frontDisplay, setFrontDisplay] = useState<'flex' | 'none'>('flex')
  const [backDisplay, setBackDisplay] = useState<'flex' | 'none'>('none')

  const onClickFullDemo = () => {
    navigate(`/access/${data.courseLink}`)
  }

  const onClickFindOutMore = () => {
    setBackDisplay('flex')
    setFrontDisplay('none')
    // disable other topics
    onSelected && onSelected(data.id)
  }

  const onHideDescription = useCallback(() => {
    setBackDisplay('none')
    setFrontDisplay('flex')
    // enable other topics
    onSelected && onSelected(null)
  }, [onSelected])

  useEffect(() => {
    // click outside of the topic to hide description
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement
      if (target.closest('.topic-container') === null) {
        onHideDescription()
      }
    })
  }, [onHideDescription])

  return (
    <div
      className="topic-container"
      style={{
        opacity: disabled ? 0.5 : 1,
        // disable hover effect
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <div
        className="topic-front"
        style={{
          ...(isMobile && {
            justifyContent: 'center',
            backgroundImage: `url(${data.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingTop: '38px',
            paddingBottom: '38px',
          }),
          display: frontDisplay,
        }}
      >
        <img src={data.imageUrl} alt={alt} />
        <div className="topic-content">
          <p>{data.name}</p>
          <button
            className="topic-front-button-mobile"
            onClick={() => onClickFindOutMore()}
          >
            Find out more
          </button>
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        <button
          className="topic-front-button-desktop"
          onClick={() => onClickFindOutMore()}
        >
          Find out more
        </button>
      </div>
      <div
        className="topic-back"
        style={{
          ...(isMobile && {
            justifyContent: 'center',
            backgroundImage: `url(${data.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }),
          display: backDisplay,
        }}
      >
        <div
          className="topic-back-mask"
          style={{
            ...(isMobile && {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }),
          }}
          onClick={() => onHideDescription()}
        >
          <p className="topic-title">{data.name}</p>
          <div
            className="topic-description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          <div
            style={{
              flexGrow: 1,
            }}
          />
          <button onClick={() => onClickFullDemo()}>Full demo</button>
        </div>
      </div>
    </div>
  )
}
