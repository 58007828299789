import React, { PropsWithChildren } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { DemoPage } from '../ui/pages/DemoPage'
import { EmailPage } from '../ui/pages/EmailPage'
import { HomePage } from '../ui/pages/HomePage'
import { ErrorPage } from '../ui/pages/ErrorPage'

export const Router: React.FC<PropsWithChildren<{}>> = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/access/:courseId',
      element: <EmailPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: `/courses/:courseId`,
      element: <DemoPage />,
      errorElement: <ErrorPage />,
    },
  ])

  return <RouterProvider router={router} />
}
