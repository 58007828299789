import './header.css'
import { useRecoilState } from 'recoil'
import { courseDemoState } from '../../state/course-demo-state'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

export const Header: React.FC = () => {
  const isMobileSmall = useMediaQuery({ query: '(max-width: 575.98px)' })
  // const isMobileMedium = useMediaQuery({ query: '(max-width: 767.98px)' })
  // const isMobileLarge = useMediaQuery({ query: '(max-width: 991.98px)' })
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listData, setListData] = useRecoilState(courseDemoState)
  const refScrollingText = useRef<HTMLDivElement>(null)
  const refTimeout = useRef<NodeJS.Timeout | null>(null)

  const onClickTopic = (courseLink: string) => {
    navigate(`/access/${courseLink}`)
  }

  // Declare the callback function
  const transitionEndHandler = useCallback(() => {
    // remove first item and append to the end
    const scrollingText = refScrollingText.current
    if (!scrollingText) return
    const firstChild = scrollingText.firstElementChild
    if (firstChild) {
      scrollingText.appendChild(firstChild)
      // reset transform
      scrollingText.style.transition = 'none'
      scrollingText.style.transform = 'translateY(0)'
    }
    // Remove the event listener to prevent multiple calls
    scrollingText.removeEventListener('transitionend', transitionEndHandler)
  }, [])

  const triggerScrollingText = useCallback(() => {
    const scrollingText = refScrollingText.current
    if (!scrollingText) return
    // animate scrolling text
    const offset = scrollingText.firstElementChild?.clientHeight || 0
    scrollingText.style.transition = 'transform 1s ease-in-out'
    scrollingText.style.transform = `translateY(-${offset}px)`

    // Listen for the transitionend event
    scrollingText.addEventListener('transitionend', transitionEndHandler)

    // Trigger the scrolling text again after a delay
    if (refTimeout.current) {
      clearTimeout(refTimeout.current as NodeJS.Timeout)
    }
    refTimeout.current = setTimeout(() => {
      triggerScrollingText()
    }, 3000)
  }, [transitionEndHandler])

  useEffect(() => {
    refTimeout.current = setTimeout(() => {
      triggerScrollingText()
    }, 1000)
  }, [triggerScrollingText])

  return (
    <header>
      <div
        id="header-bg"
        style={{ backgroundImage: 'url("/images/header-bg-home.jpg")' }}
      >
        <div
          id="header-bg-mask"
          style={{
            backgroundImage: 'url("/images/header-bg-home.svg")',
          }}
        >
          <p className="header-title">Welcome to our learning demo</p>
          <p className="header-subtitle">
            Get hands-on with topics from Mentem programs
          </p>
          <div>
            <div className="header-topics">
              <p
                className="header-content"
                style={{
                  marginRight: isMobileSmall ? '6px' : '10px',
                  whiteSpace: 'nowrap',
                }}
              >
                Find our
              </p>
              <div className="scrolling-text">
                <div id="scrolling-text-mask" ref={refScrollingText}>
                  {listData.map((item) => (
                    <p
                      key={item.id}
                      onClick={() => onClickTopic(item.courseLink)}
                    >
                      {item.shortName}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="header-text"
              style={{
                marginTop: '2px',
              }}
            >
              <p className="header-content">topic demo here</p>
            </div>
            <div className="header-arrow-down">
              <img src="/images/icon-arrow-down.svg" alt="arrow-down" />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
