import './DemoPage.css'
import { useParams } from 'react-router-dom'
import { mockData } from '../../state/course-demo-state'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import Rating from '@mui/material/Rating'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { Footer } from '../common/Footer'
import { useMediaQuery } from 'react-responsive'

const courses: any = {}

for (let c of mockData) {
  courses[c.courseLink] = c
}

export const DemoPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 575.98px)' })
  const params = useParams()
  const [showPage1, setShowPage1] = useState(true)
  const [showPage2, setShowPage2] = useState(true)
  const [showContactUs, setShowContactUs] = useState(false)
  const courseId: string = params.courseId as string
  const course = courses[courseId as string]
  const [starRating, setStarRating] = useState(
    localStorage.getItem('starRating')
      ? parseFloat(localStorage.getItem('starRating') as string)
      : -1,
  )
  const [userComments, setUserComments] = useState(
    localStorage.getItem('userComments') || '',
  )

  const handlePostRequest = async (stepNum: string) => {
    if (process.env.REACT_APP_ENV === 'local') {
      setShowContactUs(true)
      return
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/save-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        usersEmail: localStorage.getItem('usersEmail'),
        sendMeEmails: localStorage.getItem('sendMeEmails') === 'true',
        starRating: starRating ?? -1,
        userComments: userComments,
        demoStep: stepNum,
        course: courseId,
      }),
    })

    if (res.status === 201) {
      localStorage.setItem('starRating', starRating.toString())
      localStorage.setItem('userComments', userComments)
      setShowContactUs(true)
    } else {
      alert('Something went wrong. Please try again.')
      console.error(res)
      Sentry.captureException(res)
    }
  }

  return (
    <>
      <div
        id="demo-header-bg"
        style={{ backgroundImage: 'url("/images/header-bg-home.jpg")' }}
      >
        <div
          id="demo-header-bg-mask"
          style={{
            backgroundImage: 'url("/images/header-bg-demo.svg")',
          }}
        >
          <div className="demo-header-content">
            <p className="demo-header-title">{course.name}</p>
            <div className="demo-header-arrow-down">
              <img src="/images/icon-arrow-down.svg" alt="arrow-down" />
            </div>
          </div>
        </div>
      </div>
      <div id="demo-content-container">
        <div className="demo-page-padding">
          <p className="demo-title">
            Let's get hands-on with a topic from our{' '}
            <span className="highlight">{course.name}</span> course.
          </p>
        </div>
        <div
          className="demo-page-padding"
          style={{
            display: showPage1 ? 'flex' : 'none',
            backgroundImage: `url(/images/demo-bg.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '90%',
            backgroundPosition: 'center bottom',
          }}
        >
          <p className="demo-text">Self-paced online learning (20 mins)</p>
          <iframe
            title="demo"
            className="demo-iframe-content"
            src={
              course.demoPageOverride ||
              `https://s3.ap-southeast-2.amazonaws.com/dev.mentem.co/static/courses/${courseId}/index.html`
            }
          />
        </div>

        <div className="demo-page-padding">
          <div
            id="demo-show-hide-section-1"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            onClick={() => setShowPage1(!showPage1)}
          >
            <p
              className="demo-hide-section"
              style={{
                marginRight: '12px',
              }}
            >
              {showPage1 ? 'Hide' : 'Show'} section
            </p>
            {showPage1 ? (
              <RemoveIcon
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <AddIcon
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        </div>

        <div className="demo-page">
          <div
            className="demo-page-padding"
            style={{
              display: showPage2 ? 'flex' : 'none',
            }}
          >
            <p id="demo-section-2-title" className="demo-text-2">
              Now you've got a feel for one of our self-study topics, let's see
              what happens next in a real Mentem course! (5 mins)
            </p>
            <video
              controls
              controlsList="nodownload"
              className="demo-iframe-video"
            >
              <source
                src="https://content.mentem.co/videos/demo.mp4"
                type="video/mp4"
                title="UNSW Mentem - good design awards"
              />
              Your browser does not support the video tag.
            </video>
            <p className="demo-text">
              Like what you've seen so far?
              <br />
              Check out what our clients have to say, or get in touch!
            </p>
            <a
              className="btn-branded btn w-fit self-end"
              href="https://www.mentem.co/client-stories"
              target="_blank"
              rel="noreferrer"
            >
              Testimonials
            </a>
          </div>
        </div>

        <div className="demo-page-padding">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            onClick={() => setShowPage2(!showPage2)}
          >
            <p
              className="demo-hide-section"
              style={{
                marginRight: '12px',
              }}
            >
              {showPage2 ? 'Hide' : 'Show'} section
            </p>
            {showPage2 ? (
              <RemoveIcon
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <AddIcon
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        </div>

        <div
          id="demo-submit-form"
          className="demo-page"
          style={{
            background: 'black',
            display: showContactUs ? 'none' : 'flex',
          }}
        >
          <div className="demo-page-padding demo-form-padding">
            <p className="demo-text" style={{ color: 'white' }}>
              How useful did you find this demo?
            </p>
            <div className={'text-center'}>
              <Rating
                max={5}
                name="starRatingThing"
                id="starRatingThing"
                value={starRating}
                onChange={(event, newValue) => {
                  newValue && setStarRating(newValue as number)
                  event.preventDefault()
                }}
                icon={<img src="/images/star-on.svg" alt="star" />}
                emptyIcon={<img src="/images/star-off.svg" alt="star" />}
                sx={{
                  width: isMobile ? '80%' : '60%',
                  justifyContent: 'space-evenly',
                }}
              />
            </div>
            <p className="demo-text" style={{ color: 'white' }}>
              Is there anything else you’d like to share with Mentem?
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <textarea
                className="form-control"
                style={{
                  outline: 'none',
                  padding: '4px',
                }}
                value={userComments}
                onChange={(e: any) => {
                  setUserComments(e.target.value)
                }}
                rows={6}
              />
              <p
                style={{
                  color: 'black',
                  background: 'white',
                  fontSize: '12px',
                  textAlign: 'right',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  paddingRight: '12px',
                }}
              >
                300 words maximum
              </p>
            </div>
          </div>
        </div>

        <div
          className="demo-page-padding"
          style={{
            display: showContactUs ? 'none' : 'flex',
            marginTop: '24px',
          }}
        >
          <button
            onClick={() => handlePostRequest('4')}
            className={'btn-branded btn w-fit self-end'}
          >
            Submit
          </button>
        </div>

        <div
          id="demo-contact-us"
          className="demo-page-padding"
          style={{
            display: showContactUs ? 'flex' : 'none',
          }}
        >
          <p className="demo-text">Thank you for your time and feedback.</p>
          <a
            className="btn-branded btn w-fit self-end"
            style={{
              textTransform: 'none',
            }}
            href="https://www.mentem.co/contact"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
        </div>
        <div
          className="demo-page-padding"
          style={{
            marginTop: '-16px',
          }}
        >
          <a
            className="btn-branded btn w-fit self-end"
            style={{
              textTransform: 'none',
            }}
            href="/"
            target="_self"
            rel="noreferrer"
          >
            Back to start
          </a>
        </div>
      </div>
      <Footer />
    </>
  )
}
