import './footer.css'

export const Footer: React.FC = () => {
  return (
    <footer>
      <div
        className="container"
        style={{
          position: 'relative',
          zIndex: 2,
          margin: '0 auto',
          width: '92vw',
          maxWidth: '1340px',
        }}
      >
        <div className="grid-6-6">
          <div className="order-2-mob footer-address-wrapper">
            <div>
              <p>
                <b>Acknowledgment of Country</b>
              </p>
              <br />
              <p>
                Mentem is located on the unceded territory of the Bedegal
                people. We pay our respects to the traditional owners of the
                lands where we are based and to Elders past and present.
              </p>
              <br />
              <p>Address: UNSW Sydney, High St, Kensington NSW 2033.</p>
            </div>
            <img
              alt="UNSW"
              src="/images/unsw-logo_ATavT.png"
              decoding="async"
              loading="lazy"
              height="55"
              width="131"
              className="footer-logo"
            />
          </div>
          <div className="order-1-mob grid-6-6-mob">
            <div className="footer-links">
              <a
                href="https://www.mentem.co/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Home
              </a>
              <a
                href="https://www.linkedin.com/company/mentembyunsw/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Careers{' '}
              </a>
              <a
                href="https://www.mentem.co/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
            <div>
              <div className="footer-contact">
                <p>
                  <a href="mailto:hello@mentem.co">
                    <span style={{ color: '#ba61ff' }}>hello@mentem.co</span>
                  </a>
                  <br />
                  UNSW Sydney, High St, Kensington NSW 2033
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
