import './HomePage.css'
import { Footer } from '../common/Footer'
import { Header } from '../common/Header'
import { Topic } from '../common/Topic'
import { useRecoilState } from 'recoil'
import { courseDemoState } from '../../state/course-demo-state'
import { useState } from 'react'

export const HomePage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listData, setListData] = useRecoilState(courseDemoState)
  const [selectedId, setSelectedId] = useState<string | null>(null)

  return (
    <>
      <Header />
      <div className="home-container">
        <div className="home-title">
          <span>What would you like to</span>
          <span>&nbsp;</span>
          <span
            style={{
              color: '#ba61ff',
            }}
          >
            learn
          </span>
          <span>&nbsp;</span>
          <span>about?</span>
        </div>
        <div className="topics-grid-container">
          {listData.map((item) => (
            <Topic
              key={item.id}
              data={item}
              disabled={selectedId ? selectedId !== item.id : false}
              onSelected={(id) => setSelectedId(id)}
            />
          ))}
        </div>
        {/* <div className="show-more">
          <span>Show more</span>
          <img src="/images/icon-plus.svg" alt="show-more" />
        </div> */}
      </div>
      <Footer />
    </>
  )
}
