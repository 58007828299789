import React, { useEffect } from 'react'
import { RootProvider } from './providers/RootProvider'
import ReactGA from 'react-ga4'

export const App: React.FC = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    })
  }, [])
  return <RootProvider />
}

export default App
